import React from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import '../styles/services.css'
import sketchservices from "../images/sketch.jpg" 
import mktpic from "../images/mkt-pic.png" 
import brandingpic from "../images/branding-pic.png" 
import webpic from "../images/webdesign-pic.png" 
import animationpic from "../images/video-pic.png" 
import codepic from "../images/webdev-pic.png" 
import editorialpic from "../images/editorial-pic.png" 
import agriangus from "../images/agriangus-logo.png" 
import farmin from "../images/farmin-logo.png" 
import fpa from "../images/fp-a.png" 
import gloveit from "../images/glove-it.png" 
import rightconn from "../images/rc.png" 
import gerosad from "../images/gerosad-logo.png" 
import malp from "../images/logomalp.png" 
import vividfoods from "../images/vivid.png" 
import ours from "../images/ours-logo.png" 
import ilustracaoservices from "../images/ilustracaoservices.png" 
import $ from 'jquery/dist/jquery'
import Layout from "../components/layout"





const IndexPage = () => {
    return (
        <Layout>
               <div id="container-title">

<div id="wrapper-conteudo">
   <div id="conteudo-100-col">
      <p data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="titulo-texto gt-light">Global thinking 🔮</p>
      <h2 data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" class="texto gt-light">We are a company that was born in Aveiro motivated by the innovation, demand, management and quality of work. Our mission is to collaborate with companies to increase their digital value and put their ideas on the table. For us, every project is a new beginning of success.</h2>
   </div>
</div>
</div>
<div id="container-post2">
<div id="wrapper-conteudo">
   <div id="conteudo-50-col" class="col col-60">
      <div id="post2-1">
      <img id="img-sketch" src={sketchservices}  alt="forty degrees" />

      </div>
   </div>
   <div id="conteudo-50-col" class="col vertical col-40">
      <div id="post2-2" class="processo">
         <div class="bs-vertical-wizard gt-regular">
            <ul>
               <li class="complete">
                  <a>Research <i class="ico fa fa-check ico-green"></i>
                  </a>
               </li>
               <li class="complete">
                  <a>Strategy <i class="ico fa fa-check ico-green"></i>
                  </a>
               </li>
               <li class="complete">
                  <a>Design <i class="ico fa fa-check ico-green"></i>
                  </a>
               </li>
               <li class="complete">
                  <a>Development<i class="ico fa fa-check ico-green"></i>
                  </a>
               </li>
               <li class="complete">
                  <a>Test <i class="ico fa fa-check ico-green"></i>
                  </a>
               </li>
               <li class="complete">
                  <a>Delivery <i class="ico fa fa-check ico-green"></i>
                  </a>
               </li>
            </ul>
         </div>
      </div>
   </div>
</div>
</div>
<div id="container-thinking">
<div id="wrapper-conteudo">
   <div id="conteudo-100-col">
      <h1 data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="titulo-texto gt-light">We craft digital experiences</h1>
      <h2 data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" class="texto gt-light">We create brands and experiences that can emerge in the digital world. We're able to define the goals and obstacles on a new digital product process development.
         If you need to change your company's paradigm, look at the services we have available.</h2>
         <img class="imagem-services" src={ilustracaoservices}  alt="forty degrees" />

   </div>
</div>

</div>

<div id="container-cards">
   <div id="wrapper-conteudo">
      <div class="col col-25">
         <div data-sal="slide-up" data-sal-delay="0" data-sal-duration="800" data-sal-easing="ease" id="cartao1" class="cartao ">
            <h1 class="gt-light titulo-cartao">Branding and marks</h1>
            <p class="gt-light descricao-cartao">Each brand tells a story.
                             <br></br> Design, construction and application of a brand to various graphic supports and digital media are part of the process.</p>
                             {/* <button class="btn-cartao">Ask for a budget</button> */}
         </div>
      </div>
      <div class="col col-25">
         <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" id="cartao1" class="cartao">
         <h1 class="gt-light titulo-cartao"> Digital product design</h1>
         <p class="gt-light descricao-cartao">Web plataform design based on all UX / UI notions. User interations are our priority. </p>
         {/* <button class="btn-cartao">Ask for a budget</button> */}
         </div>
      </div>
      <div class="col col-25">
         <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" id="cartao1" class="cartao">
         <h1 class="gt-light titulo-cartao"> Digital product development</h1>
         <p class="gt-light descricao-cartao">Implementation of any kind of web or native application using easy-to-use methodologies. The final result is the layout approved by the client.</p>
         {/* <button class="btn-cartao">Ask for a budget</button> */}

         </div>
      </div>
      <div class="col col-25">
         <div data-sal="slide-up" data-sal-delay="900" data-sal-duration="800" data-sal-easing="ease" id="cartao1" class="cartao">
         <h1 class="gt-light titulo-cartao"> Video and motion</h1>
         <p class="gt-light descricao-cartao">Development of conceptual or literal vector animations based on illustrations. Video capture and post-production edition.</p>
         </div>
      </div>
      
   </div>
   {/* <button class="btn-services center gt-regular">Interested in our services?</button> */}

</div>

           
   
    <div id="container-partners">

<div id="wrapper-conteudo">
   <div id="conteudo-100-col">
      <p class="titulo-texto gt-light">Amazing partners</p>
      <p class="texto gt-light">The best for the bests.
        We are pleased to work with these fantastic companies. Thank you for growing and helping us to grow too.</p>
   </div>
</div>
   <div id="wrapper-conteudo-partners">

   <div id="conteudo-100-col" class="row1-partners">
    <div data-sal="slide-up" data-sal-delay="0" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
    <img class="partner-malp greyscale" src={malp}  alt="forty degrees" />

    </div>
    <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula ">
    <img class="partner greyscale" src={vividfoods}  alt="forty degrees" />

    </div>
    <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
    <img class="partner greyscale" src={ours}  alt="forty degrees" />

    </div>
</div>
<div id="conteudo-100-col" class="row2-partners">

    <div   data-sal="slide-up" data-sal-delay="0" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
        <img class="partner-farmin greyscale" src={farmin}  alt="forty degrees" />

    </div>
    <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
    <img class="partner-agriangus greyscale" src={agriangus}  alt="forty degrees" />

    </div>
    <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
    <img class="partner greyscale" src={fpa}  alt="forty degrees" />

        </div>

    </div>
    <div  id="conteudo-100-col" class="row3-partners">
        <div data-sal="slide-up" data-sal-delay="0" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
        <img class="partner-glove greyscale" src={gloveit}  alt="forty degrees" />

        </div>
        <div data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
        <img class="partner-right greyscale" src={rightconn}  alt="forty degrees" />

        </div>
        <div data-sal="slide-up" data-sal-delay="600" data-sal-duration="800" data-sal-easing="ease" class="col col-33 center vertical celula">
        <img class="partner greyscale" src={gerosad}  alt="forty degrees" />

        </div>
    </div>

        
</div>
</div>

        </Layout>

    )
}



export default IndexPage
